import $ from 'jquery';
//import jQuery from 'jquery';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';

import './scss/app.scss';

window.$ = $;

$(document).ready(function(){
	console.log('document ready!');

	function scrollToID(){
		//Add class to navigation items which should not go to a dedicated page
		$('#nav-main li.doScroll a').addClass('scrollToID');

		$('.scrollToID').on('click', function(event) {
			var section;
			section = $(this).attr('href');
				
			event.preventDefault();
				
			$('html, body').animate(
				{scrollTop: $(section).offset().top -100}, 1000);

			//$('#header li.active').removeClass('active');
			//(this).parent('li').addClass('active');
			
			return false;
		});
	}

	function scrollToProjectOverview(){
		//Add class to navigation items which should not go to a dedicated page

		$('.projects__cta a').on('click', function(event) {
			console.log('test klik');

			$('html, body').animate(
				{scrollTop: $('.portfolio').offset().top -100}, 1000);
			

			var hash = $(this).attr('href');
			hash = hash.replace(/^#/, '');

			console.log( hash );

			$('.portfolio__item').each(function(index){
				if($(this).hasClass(hash)){
					$(this).addClass('reveal');
				}else{
					$(this).removeClass('reveal');
				}
			})

			return false;
		});
	}

	// Testimonials slider homepage
	function owlCarouselTestimonials(){
		$('.testimonials-slider').owlCarousel({
		    loop:true,
		    margin:10,
		    nav:true,
		    responsive:{
		        0:{
		            items:1
		        },
		        600:{
		            items:3
		        },
		        1000:{
		            items:5
		        }
		    }
		})
	}

	// Contact form 7 checkboxes
	function contactCheckboxes(){
		 $('.script-checkbox label input').change(function(){
	      if($(this).is(':checked')) {
	          $(this).parent('label').addClass('label-selected');
	          console.log('add label');
	      } else {
	          $(this).parent('label').removeClass('label-selected');
	          console.log('remove label');
	      }
	  	 });
	}

	// Projecten overzicht
	function projectOverview(){
		$('.portfolio__navigation a').on('click', function(){
			
			var hash = $(this).attr('href');
			hash = hash.replace(/^#/, '');

			console.log( hash );

			$('.portfolio__item').each(function(index){
				if($(this).hasClass(hash)){
					$(this).addClass('reveal');
				}else{
					$(this).removeClass('reveal');
				}
			})
		});
	}

	// activate functions
	scrollToID();
	//owlCarouselTestimonials();
	contactCheckboxes();
	projectOverview();	
	scrollToProjectOverview();

});